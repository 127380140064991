import withAuth from 'helpers/withAuth';
import Layout from 'components/Layout';
import { MutableRefObject, createRef, useEffect, useState } from 'react';
import Loading from 'components/Loading';
import { Col, Input, Modal, Row, Space, notification } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { StyledButton } from 'styles/styled';
import { PaginationConfig } from 'components/Common/Pagination';
import { AddCircle } from 'iconsax-react';
import AnnouncementList from 'components/Announcement/AnnouncementList';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserSelector } from 'containers/Auth/authSlice';
import { PAGINATION, ROLE_PAGE } from 'constants/index';
import DAService from 'services/DAService';
import { formatDate } from 'helpers';
import ProviderService from 'services/ProviderService';

const Announcement = () => {
    const navigate = useNavigate();
    const [keyword, setKeyword] = useState('');
    const pageToken = createRef<string>();
    const [allProvider, setAllProvider] = useState<Array<any>>([]);
    const [pagination, setPagination] = useState<PaginationConfig>({
        limit: PAGINATION.LIMIT_10,
        current: 1,
        total: 0,
        nextToken: null,
        data: [],
    });
    const [isLoadingProviders, setIsLoadingProviders] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<any>([]);
    const user = useSelector(selectUserSelector);
    const role: any = user?.role;
    const location: Location = useLocation();

    useEffect(() => {
        if (role && !ROLE_PAGE.ANNOUNCEMENT.includes(role)) {
            navigate('/');
        }
    }, [role]);

    useEffect(() => {
        handleGetAllData();
    }, [pagination.current]);

    useEffect(() => {
        const providerData = (location.state as any)?.providers;
        if (!providerData?.length) {
            getProviders();
        } else {
            setAllProvider([...providerData]);
        }
    }, []);

    const handleActionSearch = (value: string) => {
        handleGetAllData();
    };

    const handleSearch = () => {
        handleActionSearch(keyword);
    };

    const onPageChange = (page: number, data: any = []) => {
        setPagination({
            ...pagination,
            current: page,
            data,
        });
    };

    const getProviders: { (_?: number): Promise<void> } = async (pageSize = 250) => {
        setIsLoadingProviders(true);
        try {
            const { data: { data } = {} } = await ProviderService.doctor.getList(
                null,
                pageSize,
                pageToken.current,
                true,
            );

            (pageToken as MutableRefObject<string>).current = data.nextToken;

            setAllProvider(([...prev]) => {
                const validNewProviders = data.items?.filter(
                    (newProvider: any) =>
                        newProvider.status === 'Active' &&
                        !prev?.some((item) => item.id === newProvider.id),
                ) || [];

                return [...prev, ...validNewProviders]?.map((item: any) => ({
                    ...item,
                    name: item.full_name || item.clinic_name,
                }))?.sort((a, b) => a.name.localeCompare(b.name));;
            });

            if (data.nextToken) {
                return getProviders(pageSize);
            } else {
                setIsLoadingProviders(false);
            }
        } catch (error: any) {
            notification.error({
                message: 'Error',
                description: error.data
                    ? Array.isArray(error.data.message)
                        ? error.data.message[0]
                        : error.data.message
                    : error.message,
            });
        }
    };

    const handleGetAllData = async () => {
        setLoading(true);
        try {
            const { data } = await DAService.announcements.getListAnnouncement(
                keyword,
                pagination.limit,
                pagination.current,
            );
            const newData = data?.items.map((item: any, index: number) => {
                return {
                    ...item,
                    id_number: index + 1,
                    createdAt: formatDate(item.createdAt, 'DD/MM/YYYY hh:mm:ss', ''),
                    release_date: formatDate(item.release_date, 'DD/MM/YYYY', ''),
                    viewers: item.viewers ?? 0,
                };
            });
            setList([...newData]);
            setPagination({
                ...pagination,
                total: data?.totalPage * pagination.limit,
                nextToken: data?.nextToken,
                data: data?.items || [],
            });
        } catch (error: any) {
            notification.error({
                message: 'Error',
                description: error.data.message,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = (announcementId: string) => {
        Modal.confirm({
            title: 'Delete Announcement',
            content: 'Announcement will be permanently removed. Are you sure?',
            okText: 'Remove',
            cancelText: 'Cancel',
            maskClosable: true,
            onOk: async () => {
                try {
                    await DAService.announcements.delete(announcementId);
                    handleGetAllData();
                    notification.success({
                        message: 'Success',
                        description: 'Announcement deleted successfully!',
                    });
                } catch (error) {
                    notification.error({
                        message: 'Error',
                        description: 'Announcement cannot be deleted',
                    });
                }
            },
        });
    };

    return (
        <Layout title="Announcement">
            {loading || isLoadingProviders ? <Loading /> : null}
            <div className="inline-flex items-center w-full">
                <div className="left inline-flex items-center gap-4 w-1/2">
                    <Input
                        prefix={<SearchOutlined size={18} />}
                        style={{ borderRadius: 16 }}
                        value={keyword || ''}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch();
                            }
                        }}
                        placeholder="Search for title, created date or release date (dd/MM/yyyy)"
                        onChange={(event) => setKeyword(event.target.value)}
                    />
                    <StyledButton shape="round" onClick={handleSearch}>
                        Search
                    </StyledButton>
                </div>
                <div className="right ml-auto">
                    <Space size={10} align="center">
                        <StyledButton
                            shape="round"
                            type="primary"
                            icon={<AddCircle size={18} />}
                            onClick={() => navigate('/announcement/create', { state: { providers: allProvider } })}
                        >
                            Create New Announcement
                        </StyledButton>
                    </Space>
                </div>
            </div>
            <div className="w-full mt-8">
                <AnnouncementList
                    list={list}
                    pagination={pagination}
                    onPageChange={onPageChange}
                    onDelete={handleDelete}
                    providerData={allProvider}
                ></AnnouncementList>
            </div>
        </Layout>
    );
};

export default withAuth(Announcement);
