import { StyledTable } from 'styles/styled';
import { StyledWrapper } from './style';
import Pagination from 'components/Common/Pagination';
import { Button, Tooltip } from 'antd';
import { EditIcon } from 'components/Common/Icon';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon } from 'components/icons/deleteIcon';
import moment from 'moment';

const AnnouncementList = ({ list, providerData, pagination, onPageChange, onDelete, ...props }: any) => {
    const navigate = useNavigate();
    const columns = [
        {
            title: 'ID',
            key: 'id_number',
            dataIndex: 'id_number',
        },
        {
            title: 'Created Date',
            key: 'createdAt',
            dataIndex: 'createdAt',
        },
        {
            title: 'Announcement Title',
            key: 'title',
            dataIndex: 'title',
        },
        {
            title: 'Release Date',
            key: 'release_date',
            dataIndex: 'release_date',
        },
        {
            title: 'Provider Network',
            dataIndex: 'id',
            render: (id: string, rowData: any, index: number) => {
                if (rowData.target_providers?.includes('all')) {
                    return 'All Providers';
                }
                const maxLength = 30;
                const list = providerData?.filter((item: any) => rowData.target_providers?.includes(item.id));
                const result = list.map((item: any) => item.name)?.join(', ');
                return result.substring(0, maxLength) + (result.length > maxLength ? '...' : '');
            },
        },
        {
            title: 'Viewers',
            dataIndex: 'id',
            render: (id: string, rowData: any, index: number) =>
                `${rowData.read_providers?.length ?? 0}/${
                    rowData.target_providers?.includes('all')
                        ? providerData?.length
                        : rowData.target_providers?.length ?? 0
                }`,
        },
        {
            dataIndex: 'id',
            render: (id: string, rowData: any, index: number) => {
                const isReleased = rowData && moment(rowData.release_date, 'DD/MM/YYYY') <= moment(new Date());
                return (
                    <div className="inline-flex items-center gap-2">
                        <Tooltip title="View details">
                            <Button
                                type="link"
                                size="small"
                                onClick={() =>
                                    navigate(`/announcement/view/${id}`, { state: { providers: providerData } })
                                }
                            >
                                View
                            </Button>
                        </Tooltip>
                        {!isReleased && (
                            <div>
                                <Tooltip title="Edit">
                                    <Button
                                        type="link"
                                        icon={<EditIcon />}
                                        size="small"
                                        onClick={() =>
                                            navigate(`/announcement/edit/${id}`, { state: { providers: providerData } })
                                        }
                                    />
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <Button
                                        type="link"
                                        icon={<DeleteIcon />}
                                        size="small"
                                        onClick={() => onDelete(id)}
                                    />
                                </Tooltip>
                            </div>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <StyledWrapper>
                <StyledTable columns={columns} dataSource={list} pagination={false} />
                <div className="flex justify-end mt-5">
                    <Pagination pagination={pagination} onChange={onPageChange} />
                </div>
            </StyledWrapper>
        </>
    );
};

export default AnnouncementList;
