import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Row,
  Space,
  Form,
  Select,
  DatePicker,
} from "antd";
import ArchiveItem from "components/Dashboard/ArchiveItem";
import Layout from "components/Layout";
import { selectUserSelector } from "containers/Auth/authSlice";
import withAuth from "helpers/withAuth";
import { useEffect, useState } from "react";
import { RiFilter3Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import { StyledButton, StyledDrawer, StyledScroll } from "styles/styled";
import DAService from "services/DAService";
import moment from "moment";
import {
  CANCELLED,
  CLAIMS_STATUS_APPROVAL_REJECT,
  CLAIM_STATUS_COLOR,
  FILTER_CASE_STATUS,
  PAGINATION,
  ROLE_PAGE,
} from "../../constants";
import Pagination, { PaginationConfig } from "components/Common/Pagination";
import Loading from "components/Loading";
import { formatNumber } from "helpers";
import SpaceView from "components/Space";
import produce from "immer";

const { RangePicker } = DatePicker;

function Archive() {
  const user = useSelector(selectUserSelector);
  const [loading, setLoading] = useState(false);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [actionSearch, setActionSearch] = useState(false);
  const [fieldSort, setFieldSort] = useState('date_of_submission');
  const [sortDateFilter, setSortDateFilter] = useState('desc');
  const [sortAmountFilter, setSortAmountFilter] = useState('asc');
  const [orderData, setOrderData] = useState("sortDate");
  const [sortDesc, setSortDesc] = useState("createdAt");
  const [filterByDate, setFilterByDate] = useState<{
    startDate: null | number;
    endDate: null | number;
  }>({
    startDate: null,
    endDate: null,
  });
  const [sortAsc, setSortAsc] = useState("");
  const [cases, setCases] = useState<any>([]);
  const [keyword, setKeyword] = useState("");
  const [pagination, setPagination] = useState<PaginationConfig>({
    limit: PAGINATION.LIMIT_10,
    current: 1,
    total: 0,
    nextToken: null,
    data: [],
  });
  const sortDate = ["createdAt", "date_of_submission", "date_of_admission"];

  useEffect(() => {
    let role: any = user?.role;
    if (role && !ROLE_PAGE.ARCHIVE.includes(role)) {
      // navigate(-1);
    }
  }, []);

  useEffect(() => {
    if (pagination.data && pagination.data.length) {
      setCases(pagination.data);
    } else {
      fetchDataCase();
    }
  }, [pagination.current]);

  const updateSortData = (field: string) => {
    if (sortDateFilter === "desc" || sortAmountFilter === "desc") {
      setSortDesc(field);
      setSortAsc("");
    } else {
      setSortDesc("");
      setSortAsc(field);
    }
  }

  const fetchDataCase = async (isSearching = false, isClearFilter = false) => {
      setLoading(true);
      const searchPageNumber = isSearching ? 1 : pagination.current;
      try {
          const { data } = await DAService.home.getListCaseArchive(
              isClearFilter ? '' : keyword,
              isClearFilter ? '' : statusFilter,
              isClearFilter ? 'createdAt' : sortDesc,
              isClearFilter ? '' : sortAsc,
              pagination.limit,
              searchPageNumber,
              FILTER_CASE_STATUS.ARCHIVE,
              filterByDate.startDate,
              filterByDate.endDate,
          );
          setCases(handleData(data?.data?.items) || []);
          setPagination({
              ...pagination,
              total: data?.data?.page_info.total,
              current: data?.data?.page_info.page,
              nextToken: data?.data?.nextToken,
              data: data?.data?.items || [],
          });
      } catch (error) {
          console.log(error);
      } finally {
          setLoading(false);
      }
  };

  const handleData = (data: any[]) => {
    data.map((ele) => {
      ele.status = CLAIM_STATUS_COLOR[ele.claim_status];
      ele.createdAt = moment(ele.createdAt).format("DD-MMM-YYYY HH:mm:ss");

      if (ele?.diagnoses)
        ele.diagnoses_name =
          ele?.diagnoses[0]?.icd_10 + " - " + ele?.diagnoses[0]?.name;

      return ele;
    });
    return data;
  };

  const onPageChange = (page: number, data: any = []) => {
    setPagination({
      ...pagination,
      current: page,
      data: [],
    });
  };

  const handleSearch = () => {
    setActionSearch(true);
    fetchDataCase(true);
  };

  const handleShowFilter = () => {
    setVisibleFilter(true);
  };

  const handleCloseFilter = () => {
    setVisibleFilter(false);
  };

  const handleSelectStatus = (val: string) => {
    setStatusFilter(val);
  };

  const handleSelectField = (val: string) => {
    if (sortDate.includes(val)) {
      setOrderData("sortDate");
    } else {
      setOrderData("sortAmount");
    }
    updateSortData(val);
    setFieldSort(val);
  };
  const handleSelectOrderDate = (val: string) => {
    if (val === "desc") {
      setSortDesc(fieldSort);
      setSortAsc("");
    } else {
      setSortDesc("");
      setSortAsc(fieldSort);
    }
    setSortDateFilter(val);
  };

  const handleSelectOrderAmount = (val: string) => {
    if (val === "desc") {
      setSortDesc(fieldSort);
      setSortAsc("");
    } else {
      setSortDesc("");
      setSortAsc(fieldSort);
    }
    setSortAmountFilter(val);
  };

  const handleClearFilter = async () => {
    handleSelectStatus('');
    handleSelectField('date_of_submission');
    setSortAsc('');
    setSortDesc('createdAt');
    setOrderData('sortDate');
    updateSortData('date_of_submission');
    setSortDateFilter('desc');
    setSortAmountFilter('asc');
    setFilterByDate({
      startDate: null,
      endDate: null,
    });
    setKeyword('');
    setActionSearch(true);
    fetchDataCase(false, true);
  };

  const handleFilterCase = () => {
    setActionSearch(false);
    fetchDataCase(true);
    setVisibleFilter(false);
  };

  const onChange = (value: any, dateString: [string, string] | string) => {
    setFilterByDate({
      startDate: new Date(dateString[0] + ' 00:00').getTime(),
      endDate: new Date(dateString[1] + ' 23:59').getTime(),
    });
  };

  return (
    <Layout title="Archive">
      {loading ? <Loading /> : null}
      <Row gutter={24}></Row>
      <div className="flex justify-between mb-0 mt-10">
        <b>All Cases ({formatNumber(pagination.total)})</b>
        <Space size={15}>
          <StyledButton
            style={{ border: 0, color: "#000" }}
            icon={<RiFilter3Line size={20} />}
            onClick={() => {
              handleShowFilter();
            }}
          >
            Filter
          </StyledButton>
          <Input
            prefix={<SearchOutlined size={18} />}
            style={{ borderRadius: 16 }}
            placeholder="Search"
            value={keyword || ""}
            onChange={(event) => setKeyword(event.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <StyledButton shape="round" onClick={handleSearch}>
            Search
          </StyledButton>

          <StyledButton shape="round" onClick={handleClearFilter} style={{ color: 'black', borderColor: 'black' }}>
              Clear Filter
          </StyledButton>

        </Space>
        <StyledDrawer
          title="Filter cases"
          placement={"right"}
          width={350}
          onClose={handleCloseFilter}
          visible={visibleFilter}
        >
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={24}>
              <Col span={24}>
                <p style={{ fontWeight: 'bold' }}>Status</p>
                <Select defaultValue="All" onSelect={handleSelectStatus} value={statusFilter} style={{marginBottom: 20, width: '100%'}}>
                  <Select.Option value="" selected>
                    All
                  </Select.Option>
                  {CLAIMS_STATUS_APPROVAL_REJECT
                    .concat({ value: CANCELLED, text: "Cancelled" })
                    .map((status, index) => (
                      <Select.Option key={index} value={status.value} selected>
                        {status.text}
                      </Select.Option>
                    ))
                  }
                </Select>

                <RangePicker
                  format="YYYY-MM-DD"
                  onChange={onChange}
                  value={[filterByDate.startDate ? moment(filterByDate.startDate) : null, filterByDate.endDate ? moment(filterByDate.endDate) : null]}
                />
                <SpaceView size={16} />

                <p style={{ fontWeight: 'bold' }}>Sorting</p>
                
                <Select defaultValue="date_of_submission" onSelect={handleSelectField} value={fieldSort} style={{marginBottom: 20, width: '100%'}}>
                  <Select.Option value="date_of_submission" selected>
                    Submission date
                  </Select.Option>
                  <Select.Option value="date_of_admission">
                    Admission date
                  </Select.Option>
                  <Select.Option value="total_amount">
                    PAC amount
                  </Select.Option>
                </Select>
                  
                {orderData === "sortDate" ? (
                  <Select
                    defaultValue="Newest to oldest"
                    onSelect={handleSelectOrderDate}
                    value={sortDateFilter}
                    style={{width: '100%'}}
                  >
                    <Select.Option value="desc">
                      Newest to oldest
                    </Select.Option>
                    <Select.Option value="asc">
                      Oldest to newest
                    </Select.Option>
                  </Select>
                ) : (
                  <Select
                    defaultValue="Least to greatest"
                    onSelect={handleSelectOrderAmount}
                    value={sortAmountFilter}
                    style={{width: '100%'}}
                  >
                    <Select.Option value="asc">
                      Least to greatest
                    </Select.Option>
                    <Select.Option value="desc">
                      Greatest to least
                    </Select.Option>
                  </Select>
                )}
                {/*<Form.Item
                  label="Case number"
                  name="serial"
                >
                  <Input
                    onChange={(event) => {
                      handleChangeSerial(event.target.value);
                    }}
                    placeholder="Case number" />
                </Form.Item>*/}
              </Col>
            </Row>
          </Form>
          <Row className="mt-5" gutter={24}>
            <Col span={12}>
              <Button
                className="btn-filter-cancel w-full"
                onClick={handleCloseFilter}
              >
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                className="btn-filter-apply w-full"
                onClick={handleFilterCase}
              >
                Apply
              </Button>
            </Col>
          </Row>
        </StyledDrawer>
      </div>
      {cases.length ? (
        <>
          <StyledScroll>
            <div className="mt-10">
              {cases &&
                cases.map((item: any, index: number) => (
                  <ArchiveItem
                    key={item.id}
                    pathRedirectTo={`/archive/claim/${item.id}`}
                    item={item}
                    role={user?.role}
                    isArchive
                    setExportStatus={(payload: any) => setCases(
                      produce((cases: any[]) => {
                        cases[index].v2_export = {
                          ...cases[index].v2_export,
                          ...payload
                        }
                      })
                    )}
                    className="mb-5"
                  />
                ))}
            </div>
          </StyledScroll>
          <div className="flex justify-end mt-5">
            <Pagination pagination={pagination} onChange={onPageChange} />
          </div>
        </>
      ) : (
        <div className="text-center">
          {loading ? (
            ""
          ) : actionSearch ? (
            <p>The input number is not available!</p>
          ) : (
            <p>No data found</p>
          )}
        </div>
      )}
      {/*<ModalReassign
        title="Reassign"
        visible={visible}
        onCancel={() => setVisible(false)}
      />*/}
    </Layout>
  );
}

export default withAuth(Archive);
