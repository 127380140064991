import { Tabs, Timeline, Row, Col } from "antd";
import React, { useEffect, useRef, useState, useContext } from "react";
import jwtDecoded from "utils/jwtService";
import LayoutSession from "../../layout";
import { StyledTabs } from "../../styled";
import PatientInfomation from "./PatientInfomation";
import styled from "styled-components";
import SessionA from "./SessionA";
import * as antd from "antd";
import "./styles.css"
import SessionB from "./SessionB";
import Document from "./Document";
import { MentionData } from "@draft-js-plugins/mention";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import DAService from "services/DAService";
import {
  CLAIM_STATUS_COLOR,
  CLAIM_STATUS_VALUE_MAP_TEXT,
  COMMENT_MODE,
  defaultAssement,
  Role,
} from "../../../../constants";
import { useSelector } from "react-redux";
import { selectUserSelector } from "containers/Auth/authSlice";
import { HeaderClaim } from "../HeaderClaim";
import Loading from "components/Loading";
import { downloadFileBase64 } from "utils/helper";
import { usePermissionClaim } from "hooks/usePermissionClaim";
import SpaceView from "components/Space";
import "./styles.css"

type Props = {
  isHistory: boolean;
};
const { TabPane } = Tabs;

const stackTabs = [
  "Patient Information",
  "Details of Hospitalisation",
  "Fee Breakdowns",
  "Document",
];


const AssessmentPDF = ({ isHistory = false }: Props) => {
  const user = useSelector(selectUserSelector);
  const isAuthenticated = !!user;
  const navigate = useNavigate();
  const [tabNumber, setTabNumber] = useState(1);
  const [showTabExtra, setShowTabExtra] = useState(true);
  const [data, setData] = useState<any>();
  const [dataPatient, setDataPatient] = useState<any>();
  const [mentionsDoctor, setMentionsDoctor] = useState<Array<MentionData>>([]);
  const [isPinHeader, setIsPinHeader] = useState(false);
  const [modeComment, setModeComment] = useState(COMMENT_MODE.DOCTOR);
  const [claimStatus, setClaimStatus] = useState({
    value: "",
    text: "",
    valueOrigin: "",
    color: "",
    current_assign_id: "",
    assign_status: "",
  });
  const [errors, setErrors] = useState({
    assessment: false,
    ge_advice_concurrence: false,
    message: "",
  });

  const search = useLocation().search;
  const location = useLocation();
  const version_id =
    new URLSearchParams(search).get("versionId")?.toString() ?? "";
  const { id } = useParams();
  const [role, setRole] = useState<Role>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [approvalLevel, setApprovalLevel] = useState(1);
  const [dataSessionFormB, setDataSessionFormB] = useState<any>();
  const dataSessionBRef = useRef<any>();
  const [caseLogs, setCaseLogs] = useState([]);
  const [errorsRequiredReason, setErrorsRequiredReason] = useState<any>([]);
  const { isBlockEdit } = usePermissionClaim({
    claimStatus: claimStatus,
    user: user,
    isHistory,
  });



  useEffect(() => {
    if (!isAuthenticated) {
      let searchParams = new URLSearchParams(location.search);
      let token: any = searchParams.get("token");
      jwtDecoded(token)
        .then((data: any) => {
          localStorage.setItem(
            `CognitoIdentityServiceProvider.${data.client_id}.${data.username}.accessToken`,
            token
          );
        })
        .catch((err: any) => {
          navigate("/");
        });
    }

    return () => {
      localStorage.removeItem("token");
    }
  }, [])




  useEffect(() => {
    (async () => {
      if (!id) return;
      if (isHistory && !version_id) return;
      const response = !isHistory
        ? await DAService.assessor.getDoctorPACFormB(id)
        : await DAService.historyPAC.getHistoryCaseFormB(version_id, id);
      const newData = { ...response?.data?.data };
      setDataSessionFormB(newData);
      dataSessionBRef.current = newData;
    })();
  }, [id]);

  useEffect(() => {
    const rootElement = document.getElementById("root");
    if (!rootElement) return;
    const handleScroll = () => {
      const scrollTop = rootElement.scrollTop;
      if (scrollTop > 100) {
        setIsPinHeader(true);
      } else {
        setIsPinHeader(false);
      }
    };
    rootElement.addEventListener("scroll", handleScroll);
    return () => {
      rootElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setRole(user?.role as Role);
  }, [user]);

  useEffect(() => {
    if (id) {
      getDetailCase();
      handleCallAPIGetCaseLogs();
    }
  }, [id]);

  const handleCallAPIGetCaseLogs = async () => {
    try {
      if (!id) return;
      if (isHistory && !version_id) return;
      const res = await DAService.assessor.getCaseLogs(id);
      setCaseLogs(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getDetailCase = async () => {
    try {
      if (!id) return;
      if (isHistory && !version_id) return;
      const dataFormB = !isHistory
        ? DAService.assessor.getDetailCase(id)
        : DAService.historyPAC.getHistoryCaseDetail(version_id, id);
      const dataFormPatient = !isHistory
        ? DAService.assessor.getPatient(id)
        : DAService.historyPAC.getHistoryCasePatient(version_id, id);
      const response = await dataFormB;
      const response2 = await dataFormPatient;
      setDataPatient(response2?.data?.data || {});
      setClaimStatus({
        value: response?.data?.data?.claim_status,
        valueOrigin: response?.data?.data?.claim_status,
        text: CLAIM_STATUS_VALUE_MAP_TEXT[response?.data?.data.claim_status],
        color: CLAIM_STATUS_COLOR[response?.data?.data.claim_status],
        current_assign_id: response?.data?.data?.current_assessor_id,
        assign_status: response?.data?.data?.assign_status,
      });
      setMentionsDoctor([
        {
          link: "",
          name: response?.data?.data?.doctor_name,
          avatar: "",
        },
      ]);
      setApprovalLevel(response?.data?.data?.approval_level);
      setData(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (key: string) => {
    setTabNumber(parseInt(key));
    setShowTabExtra(key != "1" && key != "4" ? false : true);
    //console.log(key);
  };

  const operations = (
    <>
      <Row className="content-right" gutter={24}>
        <Col span={12} className="pl-0">
          <span className="title">Prepared by: </span>
          <span className="value">{data?.assessor_name}</span>
        </Col>
        <Col span={12} className="pl-0">
          <span className="title">Approved by: </span>
          <span className="value">
            {data?.approved_time ? data?.current_assessor_name : "--"}
          </span>
        </Col>
      </Row>
    </>
  );

  useEffect(() => {
    if (!dataSessionFormB) return;
    if (!isBlockEdit) {
      if (
        dataSessionFormB?.assessment === null ||
        dataSessionFormB?.assessment === undefined
      ) {
        setDataSessionFormB((dt: any) => ({
          ...dt,
          assessment: defaultAssement,
        }));
        dataSessionBRef.current = {
          ...dataSessionFormB,
          assessment: defaultAssement,
        };
      }
    }
  }, [isBlockEdit, dataSessionFormB]);

  const renderDefaultTab = () => {
    if (location.hash === "#tab=patient") {
      return "1";
    } else if (location.hash === "#tab=details") {
      return "2";
    } else if (location.hash === "#tab=fee") {
      return "3";
    } else if (location.hash === "#tab=document") {
      return "4";
    } else {
      return "1";
    }
  };

  return (
    <div className="assessment-pdf" style={{backgroundColor:"#E2FBFF"}}>
      {isLoading ? <Loading /> : null}
      {/*<HeaderClaim
        claimStatus={claimStatus}
        setClaimStatus={setClaimStatus}
        showEdit={tabNumber === 1 || tabNumber === 4 ? true : false}
        caseNo={data?.serial}
        isPinHeader={isPinHeader}
        nameTab={stackTabs[tabNumber - 1]}
        setModeComment={setModeComment}
        modeComment={modeComment}
        onSubmit={handleSubmit}
        approvalLevel={approvalLevel}
        caseLogs={caseLogs}
        handleDownloadPDF={handleDownloadPDF}
        isHistory={isHistory}
      />*/}
      <div style={{ position: "relative", padding: "0 30px 35px 30px" }}>


        <PatientInfomation
          claimStatus={claimStatus}
          data={dataPatient}
          approvedName={
            data?.approved_time ? data?.current_assessor_name : ""
          }
          assessorName={data?.assessor_name}
          setData={setDataPatient}
          isHistory={isHistory}
        />

        <SpaceView size={36} />

        <SessionA
          approvedName={
            data?.approved_time ? data?.current_assessor_name : ""
          }
          claimStatus={claimStatus}
          mentionDoctor={mentionsDoctor}
          assessorName={data?.assessor_name}
          setModeComment={setModeComment}
          modeComment={modeComment}
          isPinHeader={isPinHeader}
          isHistory={isHistory}
        />

        <SpaceView size={36} />

        <SessionB
          errorsRequiredReason={errorsRequiredReason}
          setErrorsRequiredReason={setErrorsRequiredReason}
          claimStatus={claimStatus}
          mentionDoctor={mentionsDoctor}
          assessorName={data?.assessor_name}
          approvedName={
            data?.approved_time ? data?.current_assessor_name : ""
          }
          setModeComment={setModeComment}
          modeComment={modeComment}
          isPinHeader={isPinHeader}
          ref={dataSessionBRef}
          setErrors={setErrors}
          errors={errors}
          dataSessionFormB={dataSessionFormB}
          isHistory={isHistory}
        />

        <SpaceView size={36} />

        <Document
          approvedName={
            data?.approved_time ? data?.current_assessor_name : ""
          }
          assessorName={data?.assessor_name}
          role={role}
          claimStatus={claimStatus}
          setClaimStatus={setClaimStatus}
          dataCase={data}
          isHistory={isHistory}
        />


        {/*<StyledTabs
          defaultActiveKey={defaultTab}
          onChange={onChange}
          tabBarExtraContent={showTabExtra ? "" : ""}
        >

          
          <TabPane tab="Patient Information" key="1">
            <PatientInfomation
              claimStatus={claimStatus}
              data={dataPatient}
              approvedName={
                data?.approved_time ? data?.current_assessor_name : ""
              }
              assessorName={data?.assessor_name}
              setData={setDataPatient}
              isHistory={isHistory}
            />
          </TabPane>
          <TabPane tab="Details of Hospitalisation" key="2">
            <SessionA
              approvedName={
                data?.approved_time ? data?.current_assessor_name : ""
              }
              claimStatus={claimStatus}
              mentionDoctor={mentionsDoctor}
              assessorName={data?.assessor_name}
              setModeComment={setModeComment}
              modeComment={modeComment}
              isPinHeader={isPinHeader}
              isHistory={isHistory}
            />
          </TabPane>
          <TabPane tab="Fee Breakdowns" key="3">
            <SessionB
              errorsRequiredReason={errorsRequiredReason}
              setErrorsRequiredReason={setErrorsRequiredReason}
              claimStatus={claimStatus}
              mentionDoctor={mentionsDoctor}
              assessorName={data?.assessor_name}
              approvedName={
                data?.approved_time ? data?.current_assessor_name : ""
              }
              setModeComment={setModeComment}
              modeComment={modeComment}
              isPinHeader={isPinHeader}
              ref={dataSessionBRef}
              setErrors={setErrors}
              errors={errors}
              dataSessionFormB={dataSessionFormB}
              isHistory={isHistory}
            />
          </TabPane>
          <TabPane tab="Document" key="4">
            <Document
            approvedName={
              data?.approved_time ? data?.current_assessor_name : ""
            }
            assessorName={data?.assessor_name}
              role={role}
              claimStatus={claimStatus}
              setClaimStatus={setClaimStatus}
              dataCase={data}
              isHistory={isHistory}
            />
          </TabPane>
          </StyledTabs>*/}
      </div>
    </div>
  );
};

export default AssessmentPDF;

export const TimeLineItem = styled(Timeline.Item)`
  & > .ant-timeline-item-head {
    border-color: #2ac3ff;
    background-color: #2ac3ff;
  }
`;
